import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Link from '../../components/Link';

import { getImages } from '../../utils/common';

const Container = styled(Layout)`
  max-width: 100%;
`;

const Image1 = styled(Img)`
  max-width: 100%;
`;

const Button = styled(Link)`
  position: absolute;
  top: 67.6%;
  left: 50%;
  width: 21%;
  height: 1%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
`;

const Button2 = styled(Link)`
  position: absolute;
  top: 82.7%;
  left: 50%;
  width: 21%;
  height: 1%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
`;

const useImageData = () => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "images/edm" } }) {
        nodes {
          name
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return allFile.nodes;
};

const Edm202103VIPDayP2MOP = () => {
  const images = getImages(useImageData());

  return (
    <Layout padding={false}>
      <div className="position-relative">
        <Button to={'http://bit.ly/2NUthPy'} />
        <Button2 to={'http://bit.ly/3ea5QNb'} />
        <Image1 fluid={images['LPX_VIP_Week_eDM_Phase2_MOP']} />
      </div>
    </Layout>
  );
};

export default Edm202103VIPDayP2MOP;
